import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import moment from "moment";
import {
  RollingQValidation,
  portalUserValidation,
} from "../../utils/validator";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import CROSS from "../../../src/assets/img/close.png";
import { setLoadingPage } from "../../store/actions";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Quill styles

function AddPayPeriodForm() {
  const dispatch = useDispatch();
  const { employeeID } = useParams();
  const { ID } = useParams();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(customer_code, "customercode");
  const navigate = useNavigate();
  const [FormInitialData, setFormInitialData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [costCenterData, setCostCenterData] = useState(null);
  const [costCenterFilteredData, setCostCenterFilteredData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [selectAllApplication, setSelectAllApplication] = useState(false);
  const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [dropUserType, setDropUserType] = useState(null);
  const currentYear = new Date().getFullYear();
  const [CustomerCodeOptions, setCustomerCodeOptions] = useState([]);
  const years = Array.from({ length: 4 }, (_, index) => currentYear + index);
  console.log("date", beginDate, endDate);

  const [initialValues, setInitialValues] = useState({
    question_id: "",
    question: "",
    sort: "",
    status: "1",
    is_required: "1"
  });


  console.log("into", initialValues);

  useEffect(() => {
    const getCustomerCode = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/customer/customerHeaderList`
      );
      console.log(resp);
      const Detail = resp?.data?.data?.data;
      setCustomerCodeOptions(Detail);
      console.log("Detail", Detail);

    };
    getCustomerCode();
  }, []);

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      customer_code: customer_code,
      question_id: +values.question_id,
      question: values.question,
      sort: +values.sort,
      status: values.status,
      is_required: values.is_required,
    };
    try {
      setIsLoading(true);
      const resp = await axios.put(
        `${API_CALL_URL_PRIVATE}/Rolling4PayperiodQuestions/update/${ID}`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/rolling-question");
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  function formatNumberWithCommas(number) {
    return number.toLocaleString("en-US"); // Force using US locale
  }

    useEffect(() => {
      const getInitialData = async () => {
        const resp = await axios.get(
          `${API_CALL_URL_PRIVATE}/Rolling4PayperiodQuestions/detail/${ID}`
        );
        console.log(resp);
        const Detail = resp?.data?.data;
        setInitialValues((prev) => {
          return {
            ...prev,
            question_id: Detail?.question_id,
            question: Detail?.question,
            sort: Detail?.sort,
            status: Detail?.status,
            is_required: Detail?.is_required,
          };
        });
        setISInitialData(true);
      };
      getInitialData();
    }, []);

  console.log("CustomerCodeOptions", CustomerCodeOptions);

  return (
    <>
      {isInitialData && !loading_page ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={RollingQValidation}
            enableReinitialize
            validateOnChange
          >
            {(formik) => (
              <div className="main-panel">
                {console.log(formik)}
                <div className="content-wrapper">
                  <div className="page-header">
                    <h3 className="page-title">
                      <a href="#" className="badge badge-dark"></a>
                    </h3>
                    <div className></div>
                  </div>
                  <div className="row">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card">
                        <FormikForm className="forms-sample">
                          <div class="card-body">
                            <div className="row align-items-center">
                              <div className="col-3">
                                <Link
                                  to={`/admin/rolling-question`}
                                  className="f-14 white-text-dark-mode"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                  Back
                                </Link>
                              </div>
                              <div className="col-6">
                                <h4 class="card-title text-center">
                                  Update Rolling4 Question
                                </h4>
                              </div>
                            </div>

                            <div class="row">
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_code"
                              >
                                <Form.Label>Question ID</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="question_id"
                                  value={formik.values.question_id}
                                  {...formik.getFieldProps("question_id")}
                                />
                                <ErrorMessage
                                  name="question_id"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_code"
                              >
                                <Form.Label>Sort</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="sort"
                                  value={formik.values.sort}
                                  {...formik.getFieldProps("sort")}
                                />
                                <ErrorMessage
                                  name="sort"
                                  component={Texterror}
                                />
                              </Form.Group>

                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Status</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="status"
                                  value={formik.values.status}
                                  onChange={(e) => {
                                    formik.setFieldTouched(
                                      "status",
                                      true,
                                      true
                                    );
                                    if (e.target.value !== "") {
                                      formik.setFieldValue(
                                        "status",
                                        e.target.value
                                      );
                                    }
                                    // formik.setFieldValue(
                                    //   "customer_status",
                                    //   e.target.value
                                    // );
                                    // console.log(e.target.value);
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched(
                                      "status",
                                      true,
                                      true
                                    );
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </Form.Select>
                                <ErrorMessage
                                  name="status"
                                  component={Texterror}
                                />
                              </Form.Group>

                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Required</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="is_required"
                                  value={formik.values.is_required}
                                  onChange={(e) => {
                                    formik.setFieldTouched(
                                      "is_required",
                                      true,
                                      true
                                    );
                                    if (e.target.value !== "") {
                                      formik.setFieldValue(
                                        "is_required",
                                        e.target.value
                                      );
                                    }
                                    // formik.setFieldValue(
                                    //   "customer_status",
                                    //   e.target.value
                                    // );
                                    // console.log(e.target.value);
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched(
                                      "is_required",
                                      true,
                                      true
                                    );
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </Form.Select>
                                <ErrorMessage
                                  name="is_required"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-12 mb-3"
                                controlId="exampleForm.c_name"
                              >
                                <Form.Label>Question</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  placeholder=""
                                  name="question"
                                  {...formik.getFieldProps("question")}
                                />
                                <ErrorMessage
                                  name="question"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                                {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                                <Button
                                  className="ms-3"
                                  variant="success"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </Button>
                              </Form.Group>
                            </div>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddPayPeriodForm;
