import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/edit.png";
import Ico2 from "../../assets/img/delete.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from "../../assets/img/beds.png";
import Icontwo from "../../assets/img/compliance.png";
import Iconthree from "../../assets/img/variance.png";
import Iconfour from "../../assets/img/hpuoc.png";
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";
import { get, isEmpty } from "lodash";
import { API_CALL_URL_PRIVATE, API_CALL_URL_PUBLIC } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import ReactPaginate from "react-paginate";
import { setLoadingPage, setNavigate } from "../../store/actions";
import { Switch } from "antd";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Select from "react-select";
import { flushSync } from "react-dom";
import AscIcon from "../../assets/img/desc.png";
import DescIcon from "../../assets/img/asc.png";
import MultiSelect from "../../components/MultiSelectCustom/MultiSelectActivity";
import { ThemeContext } from "../../providers/ThemeProvider";
// import { useSelector } from "react-redux";

function Applications(props) {
  const dispatch = useDispatch();
  const [theme, setTheme] = useContext(ThemeContext);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [pageCounter, setPageCounter] = useState("");
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const [isStatusDrop, setIsStatusDrop] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [UserType, setUserType] = useState([]);
  const [dropUserType, setDropUserType] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  const [applicationMaster, setApplicationMaster] = useState(null);
  const [dropApplicationMaster, setDropApplicationMaster] = useState([]);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [applicationName, setApplicationName] = useState([]);
  const [costCenterName, setCostCenterName] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");


  const options = [
    { value: "forceLogout", label: "Force Logout" },
    // Add more options here if needed
  ];

  const handleSwitchChange = (status, id) => {
    document.body.style.overflow = "hidden";
    Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to ${
        status ? "Active" : "Inactive"
      } the status?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        statusUpdateApi(status, id);
      }
    });
  };
  const statusUpdateApi = async (val, id) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/portalUser/statusUpdate`,
        {
          status: parseInt(val),
          _id: id,
        }
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setStatusUpdate(!statusUpdate);
    } catch (error) {
      toast.error("Something went wrong..! Try Again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    }
  };

  useEffect(() => {
    const getUserType = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/userType/userTypeList`,
          {
            searchKeyword: "",
            limit: 50,
            page: 1,
            sort_column: "",
            sort_order: 1,
          }
        );
        const Detail = resp?.data?.data?.data?.docs;

        const drop_user_type = Detail?.map((e) => {
          return {
            label: e?.user_type_title,
            value: e?.user_type_id,
          };
        });
        setDropUserType([
          { value: "", label: "Select User Type" },
          ...drop_user_type,
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    const getCostCenter = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/departmentList`,
        {
          customer_code: parseInt(customer_code),
          limit: "100000",
        }
      );

      setCostCenterMaster(
        resp?.data?.data?.data?.docs?.map((e) => {
          return {
            cost_center_name: e.cost_center_name,
            cost_center_number: e.cost_center_number,
          };
        })
      );
      // setISInitialData(true);
      console.log(resp);
      const DropCost = resp?.data?.data?.data?.docs?.map((e) => {
        return {
          label: e.cost_center_name,
          value: e.cost_center_number,
        };
      });
      setDropCostCenterMaster((prev) => {
        return DropCost?.length > 0
          ? [{ value: "", label: "Select Cost center" }, ...DropCost]
          : [];
      });
    };
    const getApplicationMaster = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/application/applicationList`,
        {
          limit: "100000",
        }
      );
      console.log(resp);
      setApplicationMaster(resp?.data?.data?.data?.docs);
      const DropApp = resp?.data?.data?.data?.docs?.map((e) => {
        return {
          label: e.title,
          value: e.application_id,
        };
      });
      setDropApplicationMaster((prev) => {
        return DropApp?.length > 0
          ? [{ value: "", label: "Select Application Type" }, ...DropApp]
          : [];
      });
      console.log(resp);
    };
    getUserType();
    getCostCenter();
    getApplicationMaster();
  }, []);

   const getCustomerDetailList = async () => {
     try {
       const resp = await axios.post(
         `${API_CALL_URL_PRIVATE}/portalUser/userList`,
         {
           user_type: UserType?.map((e) => e.value).join(","),
           customer_code: parseInt(customer_code),
           employee_id: selectedRows,
           application_id: applicationName?.map((e) => e.value).join(","),
           cost_center_number: costCenterName?.map((e) => e.value).join(","),
           searchKeyword: searchKeyword,
           limit: pageLimit,
           page: searchActive ? 1 : currentPage,
           sort_column: searchActive ? "" : sortKey,
           sort_order: searchActive ? 1 : sortOrder,

         }
       );

       const Data = resp?.data?.data?.data;
       console.log(Data);
       setSearchActive(false);
       setCustomerData(Data);
       setPageLimit(Data?.limit);
       setTotalPages(Data?.totalPages);
       setCurrentPage(Data?.page);
       setHasNext(Data?.hasNextPage);
       setHasPrev(Data?.hasPrevPage);
       setPageCounter(Data?.pagingCounter);
       setListLoader(false);
       setISInitialData(true);
       dispatch(setLoadingPage(false));
       dispatch(setNavigate(false));
     } catch (error) {
       console.log(error);
     }
   };

  useEffect(() => {
    getCustomerDetailList();
  }, [
    searchKeyword,
    pageLimit,
    currentPage,
    sortOrder,
    customer_code,
    statusUpdate,
    UserType,
    applicationName,
    costCenterName,
    sortKey,
  ]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    setListLoader(true);
  };

  
  // const convertDateToLocalTime = (date) => {
  //   const timeZone = "America/Los_Angeles";
  //   const formattedDate = new Date(date.toLocaleString("en-US", { timeZone }));

  //   const day = formattedDate.getDate().toString().padStart(2, "0");
  //   const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  //   const year = formattedDate.getFullYear();

  //   let hours = formattedDate.getHours();
  //   const minutes = formattedDate.getMinutes().toString().padStart(2, "0");
  //   const seconds = formattedDate.getSeconds().toString().padStart(2, "0");

  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'

  //   return `${month}/${day}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
  // };
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}/${day}/${year}, ${hours}:${minutes}`;
  }

  function getDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }

  const renderSortIcon = (key) => {
    if (sortKey !== key) return <img src={DescIcon} alt="Descending" />;
    return sortOrder === -1 ? (
      <img src={DescIcon} alt="Descending" />
    ) : sortOrder === 1 ? (
      <img src={AscIcon} alt="Ascending" />
    ) : null;
  };
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === -1 ? 1 : -1);
      setCurrentPage(1);
      setListLoader(true);
    } else {
      setSortKey(key);
      setSortOrder(1);
      setCurrentPage(1);
      setListLoader(true);
    }
  };

    const handleDownloadCsv = async () => {
      const url = `${API_CALL_URL_PUBLIC}/exportCsv/exportUserCsv?user_type=${encodeURIComponent(
        UserType?.map((e) => e.value).join(",")
      )}&application_id=${encodeURIComponent(
        applicationName?.map((e) => e.value).join(",")
      )}&cost_center_number=${encodeURIComponent(
        costCenterName?.map((e) => e.value).join(",")
      )}&searchKeyword=${encodeURIComponent(
        searchKeyword
      )}&customer_code=${encodeURIComponent(parseInt(customer_code))}`;
      

      window.open(url, "_blank");
    };

    const isAllSelected =
      customerData?.docs?.length > 0 &&
      selectedRows.length === customerData.docs.length;

      const handleCheckboxChange = (id) => {
        setSelectedRows((prev) =>
          prev.includes(id)
            ? prev.filter((rowId) => rowId !== id)
            : [...prev, id]
        );
      };

      // Function to handle "Select All" checkbox
      const handleSelectAll = () => {
        if (isAllSelected) {
          setSelectedRows([]);
        } else {
          setSelectedRows(customerData.docs.map((row) => row.employee_id));
        }
      };




      const forceLogoutUser = async () => {

        if(isEmpty(selectedRows)){
           toast.error("No user has been selected for forceful logout", {
             position: "top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
           });
           return false;
        }

          document.body.style.overflow = "hidden";
          Swal.fire({
            title: "Confirmation",
            text: `Are you sure you want to forcefully logout the user`,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                const params = {
                  type: "user",
                  employee_id: selectedRows,
                  customer_code: parseInt(customer_code),
                };

                const { data } = await axios.post(
                  `${API_CALL_URL_PRIVATE}/forceLogoutUser/logout`,
                  params
                );
                setSelectedRows([]);
                toast.success("Forcefully logged out completed", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
              } catch (error) {
                console.log(error?.response?.data.message);
                toast.error(error?.response?.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
                setSelectedRows([]);
              }
            }
          });
      };

     const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);

        if (value === "forceLogout") {
          forceLogoutUser();
          setSelectedOption("");
        }
     };
  

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <a href="#" className="badge badge-dark"></a>
            </h3>
            <div className></div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-5 mobile-wrap">
                    <h4 className="card-title">All Users</h4>

                    <div
                      className="ms-4 d-flex flex-column mobile-w-auto"
                      style={{ width: "20%" }}
                    >
                      <h5 class="mx-2 my-0 fs-6">User Type</h5>
                      <MultiSelect
                        key="example_id"
                        placeholder="Select..."
                        options={dropUserType.filter(
                          (option) => option.value !== ""
                        )}
                        onChange={(e) => {
                          setUserType(e);
                          setListLoader(true);
                        }}
                        value={UserType}
                        isSelectAll={true}
                        menuPlacement={"bottom"}
                        theme={theme.theme === "LIGHT"}
                      />
                    </div>
                    <div
                      className="ms-4 d-flex flex-column mobile-w-auto"
                      style={{ width: "20%" }}
                    >
                      <h5 class="mx-2 my-0 fs-6">Application Type</h5>
                      <MultiSelect
                        key="example_id"
                        placeholder="Select..."
                        options={dropApplicationMaster.filter(
                          (option) => option.value !== ""
                        )}
                        onChange={(e) => {
                          setApplicationName(e);
                          setListLoader(true);
                        }}
                        value={applicationName}
                        isSelectAll={true}
                        menuPlacement={"bottom"}
                        theme={theme.theme === "LIGHT"}
                      />
                    </div>
                    <div
                      className="ms-4 d-flex flex-column mobile-w-auto"
                      style={{ width: "20%" }}
                    >
                      <h5 class="mx-2 my-0 fs-6">Cost center</h5>
                      <MultiSelect
                        key="example_id"
                        placeholder="Select..."
                        options={dropCostCenterMaster.filter(
                          (option) => option.value !== ""
                        )}
                        onChange={(e) => {
                          setCostCenterName(e);
                          setListLoader(true);
                        }}
                        value={costCenterName}
                        isSelectAll={true}
                        menuPlacement={"bottom"}
                        theme={theme.theme === "LIGHT"}
                      />
                    </div>

                    <Link className="boxlink" to="/admin/portal-user/add-user">
                      {" "}
                      <button type="button" class="btn btn-success">
                        Add User
                      </button>
                    </Link>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between  mobile-wrap">
                      <div class="dataTables_length" id="example_length">
                        <label>
                          Show &nbsp;
                          <select
                            name="example_length"
                            aria-controls="example"
                            class=""
                            value={pageLimit}
                            onChange={(e) => {
                              setPageLimit(e.target.value);
                              setListLoader(true);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>{" "}
                          &nbsp;entries
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <button
                            style={{ marginRight: "1rem" }}
                            type="button"
                            onClick={handleDownloadCsv}
                            disabled={isEmpty(customerData)}
                            className={`btn btn-success ${
                              isEmpty(customerData) ? "not-allowedx" : ""
                            }`}
                          >
                            Download CSV
                          </button>
                        </div>

                        <div class="dataTables_length mx-3" id="example_length">
                          {/* <button
                            style={{ marginRight: "1.2rem" }}
                            type="button"
                            onClick={forceLogoutUser}
                            className="btn btn-danger"
                          >
                            Force Logout
                          </button> */}
                          {/* <Select
                            isSearchable="true"
                            options={options}
                            className="custom-select mx-3 w-100"
                            placeholder="Bulk Action"
                            onChange={handleSelectChange}
                          /> */}
                          <select
                            onChange={handleSelectChange}
                            value={selectedOption}
                            style={{ width: "150px", padding: "0.5rem" }}
                          >
                            <option value="" disabled>
                              Bulk Action
                            </option>
                            <option value="forceLogout">Force Logout</option>
                          </select>
                        </div>
                        <div>
                          {" "}
                          <form
                            onSubmit={(e) => {
                              e.preventDefault(); // Prevent the default form submission behavior
                              if (searchKeyword !== searchTempKeyword) {
                                flushSync(() => {
                                  setSearchActive(true);
                                  setSortKey("");
                                });
                                setListLoader(true);
                                setSearchKeyword(searchTempKeyword);
                              }
                            }}
                          >
                            <div
                              id="example_filter"
                              class="dataTables_filter d-flex align-items-center justify-content-center "
                            >
                              <label>
                                Search: &nbsp;
                                <input
                                  type="text"
                                  class=""
                                  placeholder=""
                                  aria-controls="example"
                                  onChange={(e) => {
                                    setSearchTempKeyword(e.target.value);
                                    if (e.target.value === "") {
                                      setListLoader(true);
                                      setSearchKeyword("");
                                    }
                                  }}
                                  // onKeyDown={(e) => {
                                  //   if (e.key === "Enter") {
                                  //     setSearchKeyword(e.target.value);
                                  //   }
                                  // }}
                                />
                              </label>

                              <div
                                style={{
                                  border: "1px solid #aaa",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "9px",
                                  marginBottom: "7px",
                                  cursor: "pointer",
                                  marginTop: "-1px",
                                  marginLeft: "-1px",
                                }}
                                onClick={() => {
                                  if (searchKeyword !== searchTempKeyword) {
                                    flushSync(() => {
                                      setSearchActive(true);
                                      setSortKey("");
                                    });
                                    setListLoader(true);
                                    setSearchKeyword(searchTempKeyword);
                                  }
                                }}
                              >
                                <i class="ph ph-magnifying-glass"></i>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="table-responsive">
                        <table className="table filter-table">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  class="custom-checkbox"
                                  checked={isAllSelected}
                                  onChange={handleSelectAll}
                                />
                              </th>
                              <th>No.</th>
                              <th
                                onClick={() => {
                                  handleSort("employee_id");
                                }}
                              >
                                Employee Id
                                <span className="ms-1 bright">
                                  {renderSortIcon("employee_id")}
                                </span>
                              </th>
                              <th
                                onClick={() => {
                                  handleSort("employee_name");
                                }}
                              >
                                Name
                                <span className="ms-1 bright">
                                  {renderSortIcon("employee_name")}
                                </span>
                              </th>
                              <th
                                onClick={() => {
                                  handleSort("username");
                                }}
                              >
                                Username
                                <span className="ms-1 bright">
                                  {renderSortIcon("username")}
                                </span>
                              </th>

                              <th
                                onClick={() => {
                                  handleSort("employee_email");
                                }}
                              >
                                Email
                                <span className="ms-1 bright">
                                  {renderSortIcon("employee_email")}
                                </span>
                              </th>

                              <th
                                onClick={() => {
                                  handleSort("user_type");
                                }}
                              >
                                User Type
                                <span className="ms-1 bright">
                                  {renderSortIcon("user_type")}
                                </span>
                              </th>
                              <th
                                onClick={() => {
                                  handleSort("user_activity_logs.createdAt");
                                }}
                              >
                                Last Activity
                                <span className="ms-1 bright">
                                  {renderSortIcon(
                                    "user_activity_logs.createdAt"
                                  )}
                                </span>
                              </th>
                              <th
                                onClick={() => {
                                  handleSort("active_status");
                                }}
                              >
                                Active Status
                                <span className="ms-1 bright">
                                  {renderSortIcon("active_status")}
                                </span>
                              </th>
                              <th
                                onClick={() => {
                                  handleSort("updatedAt");
                                }}
                              >
                                Updated At
                                <span className="ms-1 bright">
                                  {renderSortIcon("updatedAt")}
                                </span>
                              </th>
                              <th
                                onClick={() => {
                                  handleSort("createdAt");
                                }}
                              >
                                Created At
                                <span className="ms-1 bright">
                                  {renderSortIcon("createdAt")}
                                </span>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          {isInitialData && !loading_page && !listLoader ? (
                            <>
                              {customerData?.docs.length > 0 ? (
                                <tbody>
                                  {customerData?.docs?.map((Ele, i) => {
                                    return (
                                      <tr key={Ele._id}>
                                        <td>
                                          <input
                                            type="checkbox"
                                            class="custom-checkbox"
                                            checked={selectedRows.includes(
                                              Ele?.employee_id
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(
                                                Ele?.employee_id
                                              )
                                            }
                                          />
                                        </td>
                                        <td>{i + pageCounter}</td>
                                        <td>{Ele?.employee_id}</td>
                                        <td>{Ele?.employee_name}</td>
                                        <td>{Ele?.username}</td>
                                        <td>{Ele?.employee_email}</td>

                                        <td>
                                          {Ele?.user_type === 0
                                            ? "Admin User"
                                            : "Portal User"}
                                        </td>
                                        <td>
                                          {Ele.last_login
                                            ? getDate(Ele?.last_activity_time)
                                            : ""}
                                        </td>
                                        <td className="statusTD">
                                          <Switch
                                            checked={Ele?.active_status}
                                            onChange={() => {
                                              handleSwitchChange(
                                                Ele?.active_status === 1
                                                  ? 0
                                                  : 1,
                                                Ele?._id
                                              );
                                            }}
                                            checkedChildren="Active"
                                            unCheckedChildren="Inactive"
                                          />
                                        </td>
                                        <td>{getDate(Ele?.updatedAt)}</td>
                                        <td>{getDate(Ele?.createdAt)}</td>

                                        <td>
                                          <label className="edit">
                                            <Link
                                              className="boxlink"
                                              to={`/admin/portal-user/edit-user/${Ele?._id}/${Ele?.employee_id}/${customer_code}`}
                                            >
                                              <img
                                                src={Ico1}
                                                className="w-a"
                                                alt="icon"
                                              />
                                            </Link>
                                          </label>
                                          {/* {" "}
                                  &nbsp;
                                  <label className="edit">
                                    <img src={Ico2} className="w-a" />
                                  </label> */}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan="12">
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "200px" }}
                                      >
                                        <p class="text-secondary display-6">
                                          Oops! Data Not found
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </>
                          ) : (
                            <>
                              <tbody>
                                <tr>
                                  <td colSpan="12">
                                    <div
                                      className="d-flex align-items-center justify-content-center w-100"
                                      style={{ height: "400px" }}
                                    >
                                      <span
                                        class="loader"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      ></span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                      {isInitialData && !loading_page && !listLoader ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <div
                              class="dataTables_info"
                              id="example_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {pageCounter} to{" "}
                              {Math.min(
                                pageCounter + pageLimit - 1,
                                customerData?.totalDocs
                              )}{" "}
                              of total {customerData?.totalDocs} entries
                            </div>
                            <div
                              class="dataTables_paginate paging_simple_numbers"
                              id="example_paginate"
                            >
                              <span>
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={customerData?.totalPages}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"paginationLink"}
                                  nextLinkClassName={"paginationLink"}
                                  disabledClassName={"paginationDisabled"}
                                  activeClassName={"paginationActive"}
                                  forcePage={currentPage - 1}
                                />
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Applications;
