import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { portalUserValidation } from "../../utils/validator";
import ReactDatePicker from "react-datepicker";
import CROSS from "../../../src/assets/img/close.png";
import { useSelector } from "react-redux";
import MultiSelect from "../MultiSelectCustom/MultiSelect";
import { ThemeContext } from "../../providers/ThemeProvider";

function EditPortalUserForm() {
  const parentRef = useRef(null);
  const [theme, setTheme] = useContext(ThemeContext);
  const [facilityFilterOptions, setFacilityFilterOptions] = useState([]);
  const [FacilityfilterSelected, setFacilityFilterSelected] = useState([]);
  const [applicationFilterOptions, setApplicationFilterOptions] = useState([
    {
      label: "Realtime",
      value: 101,
    },
    {
      label: "ADM",
      value: 105,
    },
  ]);
  const [applicationFilterSelected, setApplicationFilterSelected] = useState(
    []
  );

  const [businessFilterOptions, setBusinessFilterOptions] = useState([]);
  const [businessFilterSelected, setBusinessFilterSelected] = useState([]);

  const handleChangeFacility = (selected) => {
    setFacilityFilterSelected(selected);
  };
  const handleChangeApplication = (selected) => {
    setApplicationFilterSelected(selected);
  };
  const handleChangeBusiness = (selected) => {
    setBusinessFilterSelected(selected);
  };
  const { employeeID, ID, customerCode } = useParams();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const IsNavigate = useSelector((state) => state.compensate.IsNavigate);
  console.log(IsNavigate, "navigate");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [FormInitialData, setFormInitialData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [lastActivityTitle, setLastActivityTitle] = useState("");
  const [lastActivityTime, setLastActivityTime] = useState("");
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [costCenterFilteredData, setCostCenterFilteredData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [hireDate, setHireDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [terminateDate, setTerminateDate] = useState(null);
  const [selectAllApplication, setSelectAllApplication] = useState(false);
  const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [dropUserType, setDropUserType] = useState(null);
  const [firstLogin, setFirstLogin] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [lastLogin, setLastLogin] = useState("");
  const [initialValues, setInitialValues] = useState({
    facility_id: "",
    employee_id: "",
    employee_name: "",
    employee_email: "",
    cost_center_number: "",
    fte_status: 0,
    hire_date: "",
    position_control_number: "",
    termination_date: "",
    active_status: 0,
    username: "",
    password: "",
    sid: "",
    sid_email: "",
    employee_status: "Inactive",
    level_1_manager_id: null,
    level_2_manager_id: null,
    level_3_manager_id: null,
    level_4_manager_id: null,
    level_5_manager_id: null,
    level_6_manager_id: null,
    level_7_manager_id: null,
    level_8_manager_id: null,
    level_9_manager_id: null,
    level_10_manager_id: null,
    profile_image: "",
    job_title: "",
    job_category: "",
    customer_name: "",
    customer_status: 0,
    theme_mode: 0,
    cost_center_masters: [],
    facility_masters: "",
    applications: [],
    user_type: "",
    business_unit_description: "",
    realtime_landing: "1",
    show_adm_landing_table: "1",
    show_download_csv_button: 0,
    show_adm_business_unit_dropdown: 0,
  });

  useEffect(() => {
    if (IsNavigate) {
      navigate("/admin/portal-user");
    }
  }, [customer_code]);

  // const getFormatDate = (val) => {
  //   const inputDate = new Date(`${val}`);
  //   const year = inputDate.getFullYear();
  //   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(inputDate.getDate()).padStart(2, "0");
  //   return val ? `${day}/${month}/${year}` : "";
  // };

  const getFormatDate = (val) => {
    if (!val) return ""; // Handle empty or undefined input

    let inputDate;

    if (typeof val === "string") {
      // If the input is a string, attempt to parse it
      const dateComponents = val.split("/");
      if (dateComponents.length !== 3) return ""; // Handle invalid date format

      const [month, day, year] = dateComponents.map((component) =>
        parseInt(component, 10)
      );

      // Check if the parsed components are valid numbers
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return ""; // Handle invalid numeric values
      }

      inputDate = new Date(year, month - 1, day); // Note: months are zero-based in JavaScript dates
    } else if (val instanceof Date) {
      // If the input is a Date object, use it directly
      inputDate = val;
    } else {
      return ""; // Handle unsupported input type
    }

    const formattedYear = inputDate.getFullYear();
    const formattedMonth = String(inputDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(inputDate.getDate()).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  };

  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/portalUser/userDetail`,
        {
          employee_id: parseInt(employeeID),
          customer_code: parseInt(customerCode),
        }
      );
      console.log(resp);
      const Detail = resp?.data?.data?.user_details;
      console.log(Detail);
      const hdateArray = Detail?.hire_date?.split("/");
      // if (hdateArray && hdateArray.length === 3) {
      //   const [hday, hmonth, hyear] = hdateArray;
      //   setHireDate(new Date(`${hyear}-${hmonth}-${hday}`));
      // }
      // // setHireDate(new Date(`${Detail?.hire_date}`))

      // const tdateArray = Detail?.termination_date?.split("/");
      // if (tdateArray && tdateArray.length === 3) {
      //   const [day, month, year] = tdateArray;
      //   setTerminateDate(new Date(`${year}-${month}-${day}`));
      // }
      if (hdateArray && hdateArray.length === 3) {
        const [hday, hmonth, hyear] = hdateArray;

        // Check if day, month, and year are valid numbers
        if (!isNaN(hday) && !isNaN(hmonth) && !isNaN(hyear)) {
          setHireDate(new Date(`${hyear}-${hmonth}-${hday}`));
        }
      }

      const tdateArray = Detail?.termination_date?.split("/");
      if (tdateArray && tdateArray.length === 3) {
        const [day, month, year] = tdateArray;

        // Check if day, month, and year are valid numbers
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          setTerminateDate(new Date(`${year}-${month}-${day}`));
        }
      }
      setInitialValues((prev) => {
        return {
          ...prev,
          facility_id: Detail.facility_id
            ? Detail.facility_id
            : Detail.facility_masters[0]?.facility_id,
          employee_id: Detail?.employee_id,
          employee_name: Detail?.employee_name,
          employee_email: Detail?.employee_email,
          cost_center_number: Detail?.cost_center_number,
          fte_status: Detail?.fte_status,
          hire_date: Detail?.hire_date,
          position_control_number: Detail?.position_control_number,
          termination_date: Detail?.termination_date,
          active_status: Detail?.active_status,
          username: Detail?.username,
          sid: Detail?.sid,
          sid_email: Detail?.sid_email,
          employee_status: Detail?.employee_status,
          level_1_manager_id: Detail?.level_1_manager_id,
          level_2_manager_id: Detail?.level_2_manager_id,
          level_3_manager_id: Detail?.level_3_manager_id,
          level_4_manager_id: Detail?.level_4_manager_id,
          level_5_manager_id: Detail?.level_5_manager_id,
          level_6_manager_id: Detail?.level_6_manager_id,
          level_7_manager_id: Detail?.level_7_manager_id,
          level_8_manager_id: Detail?.level_8_manager_id,
          level_9_manager_id: Detail?.level_9_manager_id,
          level_10_manager_id: Detail?.level_10_manager_id,
          profile_image: Detail?.profile_image,
          job_title: Detail?.employee_job_title,
          job_category: Detail?.employee_job_category,
          customer_code: Detail?.customer_code,
          theme_mode: Detail?.theme_mode,
          user_type: Detail?.user_type,
          cost_center_masters:
            Detail?.cost_center_masters
              ?.filter((e) => e.cost_center_access)
              ?.map((e) => e.cost_center_number) || [],
          facility_masters: Detail?.facility_masters,
          applications:
            Detail?.applications
              ?.filter((e) => e.application_access)
              ?.map((e) => e.application_id) || [],
          realtime_landing: String(Detail?.realtime_landing),
          business_unit_description: Detail?.business_unit_description,
          show_adm_landing_table: String(Detail?.show_adm_landing_table),
          show_download_csv_button: Detail?.show_download_csv_button,
          show_adm_business_unit_dropdown:
            Detail?.show_adm_business_unit_dropdown,
        };
      });
      setFirstLogin(Detail?.first_login);
      setLastLogin(Detail?.last_login);
      setLastActivityTitle(Detail.last_activity_title);
      setLastActivityTime(Detail.last_activity_time);
      setCostCenterMaster(Detail?.cost_center_masters);
      setCostCenterFilteredData(Detail?.cost_center_masters);
      setFacilityMaster(Detail?.facility_masters);
      setApplications(Detail?.applications);
      setISInitialData(true);
      // setInitialImageUrl(`${DOMAIN_URL}/${Detail?.logo}`);
    };
    getInitalData();
  }, [employeeID, customer_code]);
  useEffect(() => {
    const getUserType = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/userType/userTypeList`,
          {
            searchKeyword: "",
            limit: 50,
            page: 1,
            sort_column: "",
            sort_order: 1,
          }
        );
        const Detail = resp?.data?.data?.data?.docs;

        const drop_user_type = Detail?.map((e) => {
          return {
            label: e?.user_type_title,
            value: e?.user_type_id,
          };
        });
        console.log(drop_user_type);
        setDropUserType([
          { value: "", label: "Select User Type" },
          ...drop_user_type,
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    const getFacilityList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/general/facilityMaster`,
          {
            customer_code: parseInt(customer_code),
          }
        );
        const Detail = resp?.data?.data;

        const drop_facility = Detail?.map((e) => {
          return {
            label: e?.facility_name,
            value: e?.facility_id,
          };
        });
        setFacilityFilterOptions([...drop_facility]);
      } catch (error) {
        console.log(error);
      }
    };

    const getBusinessList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/general/BusinessUnitDescriptionList`,
          {
            customer_code: parseInt(customer_code),
          }
        );
        const Detail = resp?.data?.data?.data;
        console.log("detail", Detail);
        

        const drop_facility = Detail?.map((e, index) => {
          return {
            label: e,
            value: e,
          };
        });
        setBusinessFilterOptions([...drop_facility]);
      } catch (error) {
        console.log(error);
      }
    };

    getBusinessList();
    getUserType();
    getFacilityList();
  }, []);

  useEffect(() => {
    const getCostCenter = async () => {
      setFilterLoader(true);
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/portalUser/getCostCenters`,
          {
            employee_id: parseInt(employeeID),
            customer_code: parseInt(customerCode),
            facility_id: FacilityfilterSelected?.map((e) => e.value),
            application_id: applicationFilterSelected?.map((e) => e.value),
            business_unit: businessFilterSelected?.map((e) => e.value),
          }
        );
        const Detail = resp?.data?.data?.cost_center_masters;
        setCostCenterMaster(resp?.data?.data?.cost_center_masters);
        setCostCenterFilteredData(resp?.data?.data?.cost_center_masters);
      } catch (error) {
        console.log(error);
      } finally {
        setFilterLoader(false);
      }
    };
    getCostCenter();
  }, [
    FacilityfilterSelected,
    applicationFilterSelected,
    businessFilterSelected,
  ]);

  const performSearch = (data, query) => {
    if (query.trim() === "") {
      return data || [];
    } else {
      return data?.filter(
        (e) =>
          e.cost_center_name.toLowerCase().includes(query.toLowerCase()) ||
          e.cost_center_number.toString().includes(query.toLowerCase())
      );
    }
  };

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
  };
  const formSubmit = async (values) => {
    const obj = {
      _id: ID,
      facility_id: values.facility_id,
      customer_code: parseInt(customer_code),
      employee_id: values.employee_id,
      employee_name: values.employee_name,
      employee_email: values.employee_email,
      employee_job_category: values.job_category,
      employee_job_title: values.job_title,
      cost_center_number: values.cost_center_number,
      fte_status: values.fte_status,
      hire_date: getFormatDate(values.hire_date),
      position_control_number: values.position_control_number,
      termination_date: getFormatDate(values.termination_date),
      active_status: values.active_status,
      username: values.username,
      password: values.password,
      base_pay_rate: 0,
      sid: values.sid,
      sid_email: values.sid_email,
      employee_status: values.employee_status,
      level_1_manager_id: values.level_1_manager_id,
      level_2_manager_id: values.level_2_manager_id,
      level_3_manager_id: values.level_3_manager_id,
      level_4_manager_id: values.level_4_manager_id,
      level_5_manager_id: values.level_5_manager_id,
      level_6_manager_id: values.level_6_manager_id,
      level_7_manager_id: values.level_7_manager_id,
      level_8_manager_id: values.level_8_manager_id,
      level_9_manager_id: values.level_9_manager_id,
      level_10_manager_id: values.level_10_manager_id,
      application_ids: values.applications,
      cost_center_nos: values.cost_center_masters,
      theme_mode: values.theme_mode,
      user_type: values.user_type,
      realtime_landing: parseInt(values.realtime_landing),
      business_unit_description: values.business_unit_description,
      show_adm_landing_table: parseInt(values.show_adm_landing_table),
      show_download_csv_button: values.show_download_csv_button,
      show_adm_business_unit_dropdown: values.show_adm_business_unit_dropdown,
    };
    console.log(obj);
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/portalUser/userAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, [1000]);
      // navigate("/admin/customer");
    } catch (error) {
      toast.error(error.response, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  // function formatDate(inputDate) {
  //   const date = new Date(inputDate);

  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");

  //   return `${month}/${day}/${year}, ${hours}:${minutes}`;
  // }
  function formatDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={portalUserValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel ">
              {console.log(formik)}
              <div className="content-wrapper ">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row ">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/portal-user`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Update details for :{" "}
                                {initialValues?.employee_name}
                              </h4>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-2">
                            <span className="f-14 white-text-dark-mode fw-bold">
                              First Login:{" "}
                              {firstLogin
                                ? formatDate(firstLogin)
                                : "Not Logged Yet"}
                            </span>
                            <span className="f-14 white-text-dark-mode fw-bold">
                              Last Activity:{" "}
                              {`${lastActivityTitle} - ${formatDate(
                                lastActivityTime
                              )}`}
                            </span>
                          </div>

                          <div class="row mt-4">
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Employee Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="employee_id"
                                value={formik.values.employee_id}
                                readonly
                              />
                              <ErrorMessage
                                name="employee_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="employee_name"
                                {...formik.getFieldProps("employee_name")}
                              />
                              <ErrorMessage
                                name="employee_name"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_number"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder=""
                                name="employee_email"
                                {...formik.getFieldProps("employee_email")}
                              />
                              <ErrorMessage
                                name="employee_email"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="username"
                                {...formik.getFieldProps("username")}
                              />
                              <ErrorMessage
                                name="username"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="password"
                                {...formik.getFieldProps("password")}
                              />
                              <ErrorMessage
                                name="password"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Sid</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="sid"
                                {...formik.getFieldProps("sid")}
                              />
                              <ErrorMessage name="sid" component={Texterror} />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Sid Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder=""
                                name="sid_email"
                                {...formik.getFieldProps("sid_email")}
                              />
                              <ErrorMessage
                                name="sid_email"
                                component={Texterror}
                              />
                            </Form.Group>

                            {/* <Form.Group
                            className="col-md-6 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Cost centre number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="cost_center_number"
                              {...formik.getFieldProps("cost_center_number")}
                            />
                            <ErrorMessage
                              name="cost_center_number"
                              component={Texterror}
                            />
                          </Form.Group> */}
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Realtime Landing</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="realtime_landing"
                                value={`${formik.values.realtime_landing}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "realtime_landing",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="realtime_landing"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Business Unit Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="business_unit_description"
                                {...formik.getFieldProps(
                                  "business_unit_description"
                                )}
                              />
                              <ErrorMessage
                                name="business_unit_description"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Fte Status</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="fte_status"
                                value={`${formik.values.fte_status}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "fte_status",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                              </Form.Select>
                              <ErrorMessage
                                name="fte_status"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Active Status</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="active_status"
                                value={`${formik.values.active_status}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "active_status",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                              </Form.Select>
                              <ErrorMessage
                                name="active_status"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Employee Status</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="employee_status"
                                value={`${formik.values.employee_status}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "employee_status",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="Active">Active</option>
                                <option value="Inactive">InActive</option>
                              </Form.Select>
                              <ErrorMessage
                                name="employee_status"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Job Category</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="job_category"
                                {...formik.getFieldProps("job_category")}
                              />
                              <ErrorMessage
                                name="job_category"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Job Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="job_title"
                                {...formik.getFieldProps("job_title")}
                              />
                              <ErrorMessage
                                name="job_title"
                                component={Texterror}
                              />
                            </Form.Group>

                            {/* <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Base Pay Rate</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="base_pay_rate"
                                {...formik.getFieldProps("base_pay_rate")}
                              />
                              <ErrorMessage
                                name="base_pay_rate"
                                component={Texterror}
                              />
                            </Form.Group> */}

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Hire Date</Form.Label>
                              <div>
                                <ReactDatePicker
                                  closeOnScroll={(e) => e.target === document}
                                  selected={hireDate}
                                  dateFormat="MM/dd/yyyy"
                                  onChange={(date) => {
                                    formik.setFieldTouched(
                                      "hire_date",
                                      true,
                                      true
                                    );
                                    formik.setFieldValue("hire_date", date);
                                    setHireDate(date);
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched(
                                      "hire_date",
                                      true,
                                      true
                                    );
                                  }}
                                />
                              </div>
                              <ErrorMessage
                                name="hire_date"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Termination Date</Form.Label>
                              <div>
                                <ReactDatePicker
                                  closeOnScroll={(e) => e.target === document}
                                  selected={terminateDate}
                                  dateFormat="MM/dd/yyyy"
                                  // minDate={new Date()}
                                  onChange={(date) => {
                                    formik.setFieldTouched(
                                      "termination_date",
                                      true,
                                      true
                                    );
                                    formik.setFieldValue(
                                      "termination_date",
                                      date
                                    );
                                    setTerminateDate(date);
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched(
                                      "termination_date",
                                      true,
                                      true
                                    );
                                  }}
                                />
                              </div>
                              <ErrorMessage
                                name="termination_date"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 1 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_1_manager_id"
                                {...formik.getFieldProps("level_1_manager_id")}
                              />
                              <ErrorMessage
                                name="level_1_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 2 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_2_manager_id"
                                {...formik.getFieldProps("level_2_manager_id")}
                              />
                              <ErrorMessage
                                name="level_2_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 3 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_3_manager_id"
                                {...formik.getFieldProps("level_3_manager_id")}
                              />
                              <ErrorMessage
                                name="level_3_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 4 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_4_manager_id"
                                {...formik.getFieldProps("level_4_manager_id")}
                              />
                              <ErrorMessage
                                name="level_4_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 5 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_5_manager_id"
                                {...formik.getFieldProps("level_5_manager_id")}
                              />
                              <ErrorMessage
                                name="level_5_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 6 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_6_manager_id"
                                {...formik.getFieldProps("level_6_manager_id")}
                              />
                              <ErrorMessage
                                name="level_6_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 7 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_7_manager_id"
                                {...formik.getFieldProps("level_7_manager_id")}
                              />
                              <ErrorMessage
                                name="level_7_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 8 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_8_manager_id"
                                {...formik.getFieldProps("level_8_manager_id")}
                              />
                              <ErrorMessage
                                name="level_8_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 9 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_9_manager_id"
                                {...formik.getFieldProps("level_9_manager_id")}
                              />
                              <ErrorMessage
                                name="level_9_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Level 10 Manager Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="level_10_manager_id"
                                {...formik.getFieldProps("level_10_manager_id")}
                              />
                              <ErrorMessage
                                name="level_10_manager_id"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Theme Mode</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="customer_status"
                                value={`${formik.values.theme_mode}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "theme_mode",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Dark</option>
                                <option value="0">Light</option>
                              </Form.Select>
                              <ErrorMessage
                                name="customer_status"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Facility Masters</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="facility_id"
                                value={`${formik.values.facility_id}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "facility_id",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "facility_id",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                {facilityMaster?.length > 0 ? (
                                  facilityMaster?.map((e) => {
                                    return (
                                      <option value={e?.facility_id}>
                                        {e?.facility_name}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option selected>
                                    No Facility Master available
                                  </option>
                                )}
                              </Form.Select>
                              <ErrorMessage
                                name="facility_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>User Type</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="user_type"
                                value={`${formik.values.user_type}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "user_type",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "user_type",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "user_type",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                {dropUserType?.length > 0 ? (
                                  dropUserType?.map((e) => {
                                    return (
                                      <option value={e?.value}>
                                        {e?.label}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option selected>
                                    No User Type available
                                  </option>
                                )}
                              </Form.Select>
                              <ErrorMessage
                                name="user_type"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Show ADM Landing Table</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_adm_landing_table"
                                value={`${formik.values.show_adm_landing_table}`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "show_adm_landing_table",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_adm_landing_table"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Show Download CSV Button</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_download_csv_button"
                                value={`${formik.values.show_download_csv_button}`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "show_download_csv_button",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_download_csv_button"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Display Business Unit Dropdown
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_adm_business_unit_dropdown"
                                value={`${formik.values.show_adm_business_unit_dropdown}`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "show_adm_business_unit_dropdown",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_adm_business_unit_dropdown"
                                component={Texterror}
                              />
                            </Form.Group>

                            {/* <Form.Group
                            controlId="formFile"
                            className="mb-0 col-md-6"
                          >
                            <Form.Label>Profile Image</Form.Label>
                            <Form.Control
                              type="file"
                              name="profile_image"
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "profile_image",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "profile_image",
                                  e.target.files[0]
                                );
                                const file = e.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    setSelectedImage(reader.result);
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="profile_image"
                              component={Texterror}
                            />
                            {selectedImage === null && initialImageUrl && (
                              <div
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  src={initialImageUrl}
                                  alt="Uploaded preview"
                                  style={{
                                    maxWidth: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            )}
                            {selectedImage && (
                              <div
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  src={selectedImage}
                                  alt="Uploaded preview"
                                  style={{
                                    maxWidth: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            )}
                          </Form.Group> */}
                            <div class="col-md-12 form-group my-4">
                              <Form.Label>Applications</Form.Label>
                              <div class="checkbox-group my-3">
                                <div class="checkbox-item">
                                  <input
                                    type="checkbox"
                                    class="custom-checkbox"
                                    checked={selectAllApplication}
                                    onChange={(p) => {
                                      if (p.target.checked) {
                                        setSelectAllApplication(true);
                                        const allIds = applications?.map(
                                          (e) => e.application_id
                                        );
                                        formik.setFieldValue(
                                          "applications",
                                          allIds
                                        );
                                      } else {
                                        setSelectAllApplication(false);
                                      }
                                    }}
                                  />
                                  Select all
                                </div>
                                <div class="checkbox-item">
                                  <input
                                    type="checkbox"
                                    class="custom-checkbox"
                                    checked={DeselectAllApplication}
                                    onChange={(p) => {
                                      if (p.target.checked) {
                                        setSelectAllApplication(false);
                                        setDeselectAllApplication(true);
                                        formik.setFieldValue(
                                          "applications",
                                          []
                                        );
                                      } else {
                                        setSelectAllApplication(false);
                                        setDeselectAllApplication(false);
                                      }
                                    }}
                                  />
                                  Deselect all
                                </div>
                              </div>
                              <div class="checkbox-group">
                                {applications?.map((e) => {
                                  const person =
                                    formik.values.applications?.find(
                                      (p) => p === e.application_id
                                    );
                                  const checked = person !== undefined;

                                  return (
                                    <div class="checkbox-item">
                                      <input
                                        type="checkbox"
                                        class="custom-checkbox"
                                        checked={checked}
                                        onChange={(p) => {
                                          if (p.target.checked) {
                                            setSelectAllApplication(false);
                                            setDeselectAllApplication(false);
                                            formik.setFieldValue(
                                              "applications",
                                              [
                                                ...formik.values.applications,
                                                e.application_id,
                                              ]
                                            );
                                          } else {
                                            setSelectAllApplication(false);
                                            setDeselectAllApplication(false);
                                            formik.setFieldValue(
                                              "applications",
                                              formik.values.applications.filter(
                                                (v) => v !== e.application_id
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      {e?.title}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            {/* <Form.Group
                            className="col-md-12 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              name="customer_address"
                              {...formik.getFieldProps("customer_address")}
                            />
                            <ErrorMessage
                              name="customer_address"
                              component={Texterror}
                            />
                          </Form.Group> */}
                            {/* <div class="col-md-12 form-group my-4">
                              <Form.Label>Cost center</Form.Label>
                              <div class="checkbox-group my-3">
                                <div class="checkbox-item">
                                  <input
                                    type="checkbox"
                                    class="custom-checkbox"
                                    checked={selectAllCostCenter}
                                    onChange={(p) => {
                                      if (p.target.checked) {
                                        setSelectAllCostCenter(true);
                                        setDeselectAllCostCenter(false);
                                        const allIds = costCenterMaster?.map(
                                          (e) => e.cost_center_number
                                        );
                                        formik.setFieldValue(
                                          "cost_center_masters",
                                          allIds
                                        );
                                      } else {
                                        setSelectAllCostCenter(false);
                                      }
                                    }}
                                  />
                                  Select all
                                </div>
                                <div class="checkbox-item">
                                  <input
                                    type="checkbox"
                                    class="custom-checkbox"
                                    checked={DeselectAllCostCenter}
                                    onChange={(p) => {
                                      if (p.target.checked) {
                                        setSelectAllCostCenter(false);
                                        setDeselectAllCostCenter(true);
                                        formik.setFieldValue(
                                          "cost_center_masters",
                                          []
                                        );
                                      } else {
                                        setDeselectAllCostCenter(false);
                                      }
                                    }}
                                  />
                                  Deselect all
                                </div>
                              </div>
                              <div className="checkbox-group box">
                                {costCenterMaster?.map((e) => {
                                  const person =
                                    formik.values.cost_center_masters?.find(
                                      (p) => p === e.cost_center_number
                                    );
                                  const checked = person !== undefined;
                                  console.log(checked);
                                  return e?.cost_center_name ? (
                                    <div
                                      key={e.cost_center_id}
                                      className="checkbox-item"
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        checked={checked}
                                        onChange={(p) => {
                                          if (p.target.checked) {
                                            setSelectAllCostCenter(false);
                                            setDeselectAllCostCenter(false);
                                            formik.setFieldValue(
                                              "cost_center_masters",
                                              [
                                                ...formik.values
                                                  .cost_center_masters,
                                                e.cost_center_number,
                                              ]
                                            );
                                          } else {
                                            setSelectAllCostCenter(false);
                                            setDeselectAllCostCenter(false);
                                            formik.setFieldValue(
                                              "cost_center_masters",
                                              formik.values.cost_center_masters.filter(
                                                (v) =>
                                                  v !== e.cost_center_number
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      {e.cost_center_name}
                                    </div>
                                  ) : null;
                                })}
                              </div>
                            </div> */}
                            <div class="col-md-12 form-group mt-2 mb-0">
                              <div className="row justify-content-between align-items-center">
                                <div className="col-md-1 mb-0">
                                  {" "}
                                  <Form.Label>Cost Center</Form.Label>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 form-group" ref={parentRef}>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-md-4 mb-3">
                                  {costCenterMaster?.length > 0 ? (
                                    <div class="checkbox-group red-checkbox my-3">
                                      <div class="checkbox-item">
                                        <input
                                          type="checkbox"
                                          class="custom-checkbox"
                                          checked={selectAllCostCenter}
                                          disabled={selectAllCostCenter}
                                          onChange={(p) => {
                                            if (p.target.checked) {
                                              setSelectAllCostCenter(true);
                                              setDeselectAllCostCenter(false);
                                              const allIds =
                                                costCenterMaster?.map(
                                                  (e) => e.cost_center_number
                                                );
                                              formik.setFieldValue(
                                                "cost_center_masters",
                                                allIds
                                              );
                                            } else {
                                              setSelectAllCostCenter(false);
                                            }
                                          }}
                                        />
                                        Select all
                                      </div>
                                      <div class="checkbox-item">
                                        <input
                                          type="checkbox"
                                          class="custom-checkbox"
                                          checked={DeselectAllCostCenter}
                                          disabled={DeselectAllCostCenter}
                                          onChange={(p) => {
                                            if (p.target.checked) {
                                              setSelectAllCostCenter(false);
                                              setDeselectAllCostCenter(true);
                                              formik.setFieldValue(
                                                "cost_center_masters",
                                                []
                                              );
                                            } else {
                                              setDeselectAllCostCenter(false);
                                            }
                                          }}
                                        />
                                        Deselect all
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div class="col-md-2 mb-3 position-relative">
                                  <MultiSelect
                                    key="example_id"
                                    placeholder="Filter by Business Unit"
                                    options={businessFilterOptions}
                                    onChange={handleChangeBusiness}
                                    value={businessFilterSelected}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                    theme={theme.theme === "LIGHT"}
                                  />
                                </div>
                                <div class="col-md-2 mb-3 position-relative">
                                  <MultiSelect
                                    key="example_id"
                                    parentRef={parentRef}
                                    placeholder="Filter by Application"
                                    options={applicationFilterOptions}
                                    onChange={handleChangeApplication}
                                    value={applicationFilterSelected}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                    theme={theme.theme === "LIGHT"}
                                  />
                                </div>
                                <div class="col-md-2 mb-3 position-relative">
                                  <MultiSelect
                                    key="example_id"
                                    placeholder="Filter by Facility"
                                    options={facilityFilterOptions}
                                    onChange={handleChangeFacility}
                                    value={FacilityfilterSelected}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                    theme={theme.theme === "LIGHT"}
                                  />
                                </div>
                                <div class="col-md-2 mb-3 position-relative">
                                  <input
                                    placeholder="Search here"
                                    name=""
                                    type="search"
                                    id=""
                                    class="form-control"
                                    value={searchKeyword}
                                    onChange={(e) => {
                                      setSearchKeyword(e.target.value);
                                      setCostCenterFilteredData(() => {
                                        return performSearch(
                                          costCenterMaster,
                                          e.target.value
                                        );
                                      });
                                    }}
                                  />
                                  {/* <img
                                        src={CROSS}
                                        alt="cross_btn"
                                        className="close-cost-center"
                                        onClick={() => {
                                          setSearchKeyword("");
                                          setCostCenterFilteredData(() => {
                                            return performSearch(
                                              costCenterMaster,
                                              ""
                                            );
                                          });
                                        }}
                                      /> */}
                                </div>
                              </div>
                              {!filterLoader > 0 ? (
                                <>
                                  {costCenterMaster?.length > 0 ? (
                                    <>
                                      <div className="checkbox-group box">
                                        {costCenterFilteredData?.map((e) => {
                                          const person =
                                            formik.values.cost_center_masters?.find(
                                              (p) => p === e.cost_center_number
                                            );
                                          const checked = person !== undefined;
                                          return e?.cost_center_name ? (
                                            <div
                                              key={e.cost_center_id}
                                              className="checkbox-item"
                                            >
                                              <input
                                                type="checkbox"
                                                className="custom-checkbox"
                                                checked={checked}
                                                onChange={(p) => {
                                                  if (p.target.checked) {
                                                    setSelectAllCostCenter(
                                                      false
                                                    );
                                                    setDeselectAllCostCenter(
                                                      false
                                                    );
                                                    formik.setFieldValue(
                                                      "cost_center_masters",
                                                      [
                                                        ...formik.values
                                                          .cost_center_masters,
                                                        e.cost_center_number,
                                                      ]
                                                    );
                                                  } else {
                                                    setSelectAllCostCenter(
                                                      false
                                                    );
                                                    setDeselectAllCostCenter(
                                                      false
                                                    );
                                                    formik.setFieldValue(
                                                      "cost_center_masters",
                                                      formik.values.cost_center_masters.filter(
                                                        (v) =>
                                                          v !==
                                                          e.cost_center_number
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              <span>{`${e.cost_center_number} - ${e.cost_center_name} `}</span>
                                              {/* <span className="fw-bold">
                                                {e.realtime === 1
                                                  ? "(RealTime)"
                                                  : ""}
                                              </span> */}
                                            </div>
                                          ) : null;
                                        })}
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      className="d-flex align-items-center justify-content-center"
                                      style={{ height: "200px" }}
                                    >
                                      <p>No cost center available</p>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div
                                  className="d-flex align-items-center justify-content-center w-100"
                                  style={{ height: "200px" }}
                                >
                                  <span
                                    class="loader"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  ></span>
                                </div>
                              )}
                            </div>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditPortalUserForm;
