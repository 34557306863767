import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import { PaycodeFormValidation } from "../../utils/validator";
import Texterror from "../Texterror";
import { useSelector } from "react-redux";

function AddPaycodeForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [TimezoneOptions, setTimezoneOptions] = useState([]);
  const [isInitialData, setISInitialData] = useState(false);
  const customer_code = useSelector((state) => state.compensate.customerCode);

  const [initialValues, setInitialValues] = useState({
    interface_code: "",
    description: "",
    client_mapping: "",
    ps_mapping: "",
    ps_category: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    const getTimezoneOptions = async () => {
      setISInitialData(false);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/general/timezoneMaster`
      );

      setTimezoneOptions(resp.data.data);
      setISInitialData(true);
    };
    getTimezoneOptions();
  }, []);
  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      interface_code: values.interface_code,
      customer_code: customer_code,
      description: values.description,
      client_mapping: values.client_mapping,
      ps_mapping: values.ps_mapping,
      ps_category: values.ps_category,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweekly_paycode/create`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/biweekly-paycode");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={PaycodeFormValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div className="d-flex justify-content-between">
                          <h4 class="card-title">Add Biweekly Paycode</h4>
                          <Link
                            to={`/admin/biweekly-paycode/`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <FormikForm className="forms-sample">
                          <div class="row">
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Paycode</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Paycode"
                                name="interface_code"
                                {...formik.getFieldProps("interface_code")}
                              />
                              <ErrorMessage
                                name="interface_code"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_description"
                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Description"
                                name="description"
                                {...formik.getFieldProps("description")}
                              />
                              <ErrorMessage
                                name="description"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_description"
                            >
                              <Form.Label>Client Mapping</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Client Mapping"
                                name="client_mapping"
                                {...formik.getFieldProps("client_mapping")}
                              />
                              <ErrorMessage
                                name="client_mapping"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_description"
                            >
                              <Form.Label>PS Mapping</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="PS Mapping"
                                name="ps_mapping"
                                {...formik.getFieldProps("ps_mapping")}
                              />
                              <ErrorMessage
                                name="ps_mapping"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_description"
                            >
                              <Form.Label>PS Category</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="PS Category"
                                name="ps_category"
                                {...formik.getFieldProps("ps_category")}
                              />
                              <ErrorMessage
                                name="ps_category"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}
export default AddPaycodeForm;
